import React from "react";

import styled from "styled-components";
import Line from "../../images/svg/SaaStralia-Yellow-Underline.svg";
import { COLOR, FONT, SCREEN } from "../variables";
import InnerWrapper from "../inner-wrapper";
import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  background-color: ${COLOR.green};
  padding: 45px 0 272px 0;
  position: relative;
  @media ${SCREEN.tablet} {
    padding: 80px 0;
  }

  @media ${SCREEN.desktop} {
    padding: 80px 0 120px 0;
  }

  .relative {
    position: relative;
    z-index: 3;
  }

  .flex-row {
    @media ${SCREEN.laptop} {
      display: flex;
    }

    .col {
      .subheading {
        color: ${COLOR.yellow};
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.35;
        @media ${SCREEN.tablet} {
          font-size: 1.75rem;
        }
      }

      &--one {
        display: block;
        @media ${SCREEN.laptop} {
          width: 40%;
        }
      }

      &--two {
        max-width: 650px;
        margin: 24px 0 0 0;
        
        @media ${SCREEN.tablet} {
          margin: 58px 0 0 0;
        }
        @media ${SCREEN.laptop} {
          width: 60%;
          margin: 0;
        }
        

        h4 {
          color: ${COLOR.yellow};
          font-family: ${FONT.heading};
          font-weight: 600;
          font-size: 2.3rem;
          margin: 0 0 20px 0;
          @media ${SCREEN.tablet} {
            font-size: 2.5rem;
          }
        }

        .list-item {
          color: ${COLOR.white};
          padding: 0 0 0 16px;
          margin: 30px 0 0 0;

          &__each {
            list-style-type: disc;
            margin: 0 0 10px 0;
            width: 100%;
            @media ${SCREEN.tablet} {
              width: 50%;
            }
            @media ${SCREEN.laptop} {
              width: 60%;
            }
            @media ${SCREEN.desktop} {
              width: 100%;
            }

            span {
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 1.4;
              @media ${SCREEN.tablet} {
                font-size: 1.3rem;
              }
            }
          }
        }

        .footnote {
          color: ${COLOR.white};
          margin: 34px 0 0 0;
          font-size: 1.3rem;
          max-width: max-content;
          font-weight: 400;
          @media ${SCREEN.tablet} {
            font-size: 1.5rem;
            margin: 40px 0 0 0;
            max-width: 450px;
          }
          @media ${SCREEN.desktop} {
            max-width: 500px;
          }
          @media ${SCREEN.desktopXL} {
            max-width: 570px;
          }

          .underline {
            width: 245px;
            display: block;
            margin: 0 auto;
            @media ${SCREEN.tablet} {
              width: 288px;
            }
          }
        }
      }
    }
  }

  /* .person-img {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 222px;
    @media ${SCREEN.tablet} {
      max-width: 300px;
    }
    @media ${SCREEN.laptop} {
      max-width: 330px;
    }
    @media ${SCREEN.desktop} {
      max-width: 350px;
    }
  } */

  .absolute-img {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 230px;
    z-index: 1;
    @media ${SCREEN.tablet} {
      max-width: 270px;
      bottom: 170px;
    }
    @media ${SCREEN.laptop} {
      max-width: 22vw;
      top: 200px;
      bottom: auto;
    }
    @media ${SCREEN.desktop} {
      max-width: 340px;
      bottom: 200px;
      top: auto;
    }
    @media ${SCREEN.desktopXL} {
      max-width: 350px;
      top: 140px;
    }

    img {
      z-index: 2;
    }

    &::after {
      content: "";
      background-color: ${COLOR.yellow};
      position: absolute;
      width: 100%;
      height: 100%;
      top: -10px;
      left: -10px;
      z-index: 1;
    }
  }
`;

const Store = () => {
  return (
    <Wrapper id="store">
      <InnerWrapper className="relative">
        <div className="flex-row">
          <div className="col col--one">
            <span className="subheading">What to expect?</span>
          </div>
          <div className="col col--two">
            <h4>Here's a taste of what's was part of the tour last year…</h4>
            <ul className="list-item">
              <li className="list-item__each">
                <span>Discount off the SaaStr ticket price</span>
              </li>
              <li className="list-item__each">
                <span>Pre-tour meetup</span>
              </li>
              <li className="list-item__each">
                <span>Welcome reception with Aussies and Kiwis</span>
              </li>
              <li className="list-item__each">
                <span>Austrade Lunch & Learn</span>
              </li>
              <li className="list-item__each">
                <span>Professional and social tours</span>
              </li>
              <li className="list-item__each">
                <span>Post-event meetups</span>
              </li>
            </ul>
            <p className="footnote">
              AND A LIFETIME OF FRIENDSHIPS AND MEMORIES
              <span className="underline">
                <Line />
              </span>
            </p>
          </div>
        </div>
      </InnerWrapper>
      <div className="absolute-img">
        <StaticImage
          src="../../images/Website-Image-1.png"
          alt="Awesome deal"
          placeholder="blurred"
          quality={100}
        />
      </div>
    </Wrapper>
  );
};

export default Store;
