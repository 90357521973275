import React from "react";

import styled from "styled-components";
import { COLOR, SCREEN, FONT } from "../variables";
import InnerWrapper from "../inner-wrapper";

const Wrapper = styled.section`
  padding: 45px 0;
  @media ${SCREEN.tablet} {
    padding: 80px 0;
  }

  .flex-row {
    @media ${SCREEN.laptop} {
      display: flex;
      align-items: center;
    }

    .col {
      &--one {
        display: block;
        @media ${SCREEN.laptop} {
          width: 40%;
          padding: 0 70px 0 0;
        }

        .subheading {
          color: ${COLOR.green};
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.35;
          @media ${SCREEN.tablet} {
            font-size: 1.75rem;
          }
        }

        .box {
          margin: 38px 0 0 0;
          position: relative;
          @media ${SCREEN.tablet} {
            max-width: 400px;
            margin: 58px 0 0 0;
          }

          .txt-wrap {
            background-color: ${COLOR.yellow};
            position: relative;
            z-index: 2;
            padding: 28px;
            @media ${SCREEN.tablet} {
              padding: 38px;
            }

            h3 {
              color: ${COLOR.green};
              font-size: 1.4rem;
              font-weight: 600;
              @media ${SCREEN.tablet} {
                font-size: 1.75rem;
              }
            }

            p {
              color: ${COLOR.white};
              font-size: 1.1rem;
              margin: 10px 0 0 0;
              @media ${SCREEN.tablet} {
                font-size: 1.25rem;
              }
            }
          }

          &::after {
            content: "";
            background-color: ${COLOR.green};
            position: absolute;
            width: 100%;
            height: 100%;
            top: -10px;
            right: -10px;
            z-index: 1;
          }
        }
      }

      &--two {
        max-width: 700px;
        margin: 40px 0 0 0;
        @media ${SCREEN.tablet} {
          margin: 58px 0 0 0;
        }
        @media ${SCREEN.laptop} {
          margin: 0;
          width: 60%;
        }

        h2 {
          color: ${COLOR.green};
          font-family: ${FONT.heading};
          font-weight: 600;
          font-size: 1.5rem;
          margin: 0 0 25px 0;
          @media ${SCREEN.tablet} {
            font-size: 2.6rem;
            margin: 0 0 30px 0;
          }

          strong {
            color: ${COLOR.yellow};
            font-weight: 600;
          }
        }

        .list-item {
          &__each {
            border-top: ${COLOR.grey} 1px solid;
            padding: 12px 0;

            span {
              color: ${COLOR.green};
              font-size: 1.25rem;
              font-weight: 400;
              line-height: 1.4;
              @media ${SCREEN.tablet} {
                font-size: 1.45rem;
              }
            }

            &--last {
              border-bottom: ${COLOR.grey} 1px solid;
            }
          }
        }

        .footnote {
          margin: 30px 0 0 0;

          p {
            color: ${COLOR.green};
            font-size: 1.35rem;
            font-weight: 600;
            line-height: 1.4;
            @media ${SCREEN.tablet} {
              font-size: 1.45rem;
            }

            strong {
              color: ${COLOR.yellow};
              font-weight: 600;
            }
          }
        }
      }

      .link {
        border-bottom: 2px ${COLOR.green} solid;
        color: ${COLOR.green};
        display: table;
        margin: 20px 0 0 0;
        font-weight: 700;
        font-size: 1.175rem;
        text-decoration: none;
        transition: 0.2s all ease-in-out;
        @media ${SCREEN.tablet} {
          font-size: 1.25rem;
        }
        @media ${SCREEN.cursor} {
          &:hover {
            border-bottom: 2px ${COLOR.yellow} solid;
            color: ${COLOR.yellow};
          }
        }

        &--in-yellow {
          @media ${SCREEN.cursor} {
            &:hover {
              border-bottom: 2px ${COLOR.white} solid;
              color: ${COLOR.white};
            }
          }
        }
      }
    }
  }
`;

const About = () => {
  return (
    <Wrapper id="about">
      <InnerWrapper>
        <div className="flex-row flex-row--top">
          <div className="col col--one">
            <span className="subheading">About</span>
            <div className="box">
              <div className="txt-wrap">
                <h3>
                  Enjoy highly discounted ticket prices for the world's biggest
                  and best Saas conference
                </h3>
                <p>
                  Plus other <strong>awesome benefits</strong> when you join the
                  Ozzie tour to San Jose with SaaStralia.
                </p>
                <a className="link link--in-yellow" href="#contact">
                  Enquire Now
                </a>
              </div>
            </div>
          </div>
          <div className="col col--two">
            <h2>
              SaaStr Annual is <strong>awesome.</strong>
            </h2>
            <ul className="list-item">
              <li className="list-item__each">
                <span>12,500 Cloud and SaaS Founders, VCs, and Execs.</span>
              </li>
              <li className="list-item__each">
                <span>
                  3 full days, 100's of workshops, and 1000s of Mentoring sessions.
                </span>
              </li>
              <li className="list-item__each list-item__each--last">
                <span>
                  300+ Speakers from world-leading SaaS Companies.
                </span>
              </li>
            </ul>
            <div className="footnote">
              <p>
                All to help you scale <strong>faster.</strong>
                <br />
                We know because we've been.
              </p>

              <a
                className="link"
                href="https://www.saastrannual2023.com"
                target="_blank"
              >
                Visit the SaaStr website
              </a>
            </div>
          </div>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default About;
