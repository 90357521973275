import React from "react";
import { COLOR, FONT, SCREEN } from "../variables";
import styled from "styled-components";
import InnerWrapper from "../inner-wrapper";
import bgImg from "../../images/banner.png";

const Wrapper = styled.header`
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 136px 0 90px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${SCREEN.tablet} {
    padding: 90px 0 0 0;
    min-height: 750px;
  }

  .txt-wrap {
    color: ${COLOR.white};
    text-align: center;
    max-width: 590px;

    h1 {
      font-family: ${FONT.heading};
      font-size: 2.4rem;
      font-weight: 600;
      margin: 0 0 25px 0;
      @media ${SCREEN.tablet} {
        font-size: 4.5rem;
      }
    }

    p {
      font-size: 1.2rem;
      margin: 0 0 16px 0;
      @media ${SCREEN.tablet} {
        font-size: 1.45rem;
        margin: 0 0 20px 0;
      }

      &:nth-child(3) {
        margin: 0 0 32px 0;
        @media ${SCREEN.tablet} {
          margin: 0 0 60px 0;
        }
      }

      a {
        display: block;
        color: ${COLOR.white};
        transition: all 0.2s ease-in-out;
        &:hover {
          color: ${COLOR.yellow};
        }
      }
    }
  }
`;

const Banner = () => {
  return (
    <Wrapper id="main">
      <InnerWrapper>
        <div className="txt-wrap">
          <h1>SaaStralia 2023</h1>
          <p>
            Network, connect and have fun with Kiwis and Aussies who are attending Saastr Annual in San Mateo. We are back again with the second SaaStralia tour and we'd love you to be a part of it. SaaStralia is a platform for the visiting ANZ Saas community to connect before, during and after the event.
          </p>
          <p>
            SaaStr Annual is where 12,500+ SaaS executives, founders, and VCs will come together for the world's largest Saas community event.
          </p>

          <p>
            SaaStr Annual 2023

            <a
              className="img"
              href="https://www.saastrannual2023.com"
              target="_blank"
            >
              Check out the site here
            </a>
          </p>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Banner;
