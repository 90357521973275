import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/sections/banner";
import Countdown from "../components/sections/countdown";
import About from "../components/sections/about";
import Store from "../components/sections/store";
import WhoWhat from "../components/sections/who-what";
import Gallery from "../components/sections/gallery";
import Contact from "../components/sections/contact";

const IndexPage = () => (
  <Layout>
    <Seo
      title="SaaStralia 2023"
      description={`12,500+ SaaS executives, founders, and VCs will come together for SaaStr Annual 2023, the world’s largest SaaS community event on the planet. Find out more about SaaStralia tour, we’d love you to be a part of it.`}
    />
    <Banner />
    <Gallery />
    <Store />
    <WhoWhat />

    <Countdown />
      <About />
    <Contact />
  </Layout>
);

export default IndexPage;
