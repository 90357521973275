import React from "react";

import styled from "styled-components";
import { COLOR, SCREEN, FONT } from "../variables";
import InnerWrapper from "../inner-wrapper";

const WhoWrapper = styled.section`
  padding: 45px 0;
  @media ${SCREEN.tablet} {
    padding: 80px 0;
  }

  .flex-row {
    @media ${SCREEN.laptop} {
      display: flex;
    }

    .col {
      &--one {
        display: block;
        @media ${SCREEN.laptop} {
          width: 40%;
          padding: 0 70px 0 0;
        }

        .subheading {
          color: ${COLOR.green};
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.35;
          max-width: 320px;
          display: block;
          @media ${SCREEN.tablet} {
            font-size: 1.75rem;
          }
        }
      }

      &--two {
        max-width: 700px;
        margin: 24px 0 0 0;
        @media ${SCREEN.tablet} {
          margin: 58px 0 0 0;
        }
        @media ${SCREEN.laptop} {
          width: 60%;
          margin: 0;
        }

        p {
          color: ${COLOR.black};
          font-size: 1rem;
          margin: 0 0 20px 0;
          @media ${SCREEN.tablet} {
            font-size: 1.1rem;
          }

          strong {
            font-weight: 700;
          }
        }

        h5 {
          color: ${COLOR.green};
          font-family: ${FONT.heading};
          font-weight: 600;
          font-size: 2.3rem;
          margin: 25px 0 0 0;
          @media ${SCREEN.tablet} {
            font-size: 2.5rem;
            margin: 30px 0 0 0;
          }

          strong {
            color: ${COLOR.yellow};
            font-weight: 600;
          }
        }
      }
    }
  }
`;

const WhyWrapper = styled.section`
  padding: 45px 0;
  background-color: ${COLOR.green};

  @media ${SCREEN.tablet} {
    padding: 80px 0;
  }

  .flex-row {
    @media ${SCREEN.laptop} {
      display: flex;
    }

    .col {
      &--one {
        display: block;
        @media ${SCREEN.laptop} {
          width: 40%;
          padding: 0 70px 0 0;
        }

        .subheading {
          color: ${COLOR.yellow};
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.35;
          max-width: 320px;
          display: block;
          @media ${SCREEN.tablet} {
            font-size: 1.75rem;
          }
        }
      }

      &--two {
        max-width: 700px;
        margin: 24px 0 0 0;
        @media ${SCREEN.tablet} {
          margin: 58px 0 0 0;
        }
        @media ${SCREEN.laptop} {
          width: 60%;
          margin: 0;
        }

        p {
          color: ${COLOR.white};
          font-size: 1rem;
          margin: 0 0 20px 0;
          @media ${SCREEN.tablet} {
            font-size: 1.1rem;
          }
        }

        strong {
          color: ${COLOR.yellow};
        }
      }
    }
  }
`;

const WhoWhat = () => {
  return (
    <>
      <WhoWrapper id="who&why">
        <InnerWrapper>
          <div className="flex-row flex-row--top">
            <div className="col col--one">
              <span className="subheading">Who is behind SaaStralia</span>
            </div>
            <div className="col col--two">
              <p>
                Last year was simply AMAZING. The first time we attended Saastr in 2019 we met Aussie and Kiwi founders randomly and briefly, and the connections didn't really stick. Organising the tour last year was a completely different ball game. Wondering where to grab lunch? Missing a session and want the notes? Looking for directions or help with the agenda or satellite events? The Saastralia WhatsApp group's got you.
              </p>
              <p>
                For us at CAPITAL-e, it’s about developing a community around this incredible event. A chance for us to synthesise the information and learnings that we take from each day and make them relevant to our region. We got so much out of going previously and we think we can turn that into something even more valuable.
              </p>
              <h5>
                And so SaaStralia <strong>was born.</strong>
              </h5>
            </div>
          </div>
        </InnerWrapper>
      </WhoWrapper>

      <WhyWrapper>
        <InnerWrapper>
          <div className="flex-row flex-row--top">
            <div className="col col--one">
              <span className="subheading">Why are we doing it?</span>
            </div>
            <div className="col col--two">
              <p>
                We've been to Saastr before. The resounding feedback from other
                attendees was that the content was amazing. In speaking to other
                Aussie attendees, there's a sense that building a tour would
                create a wonderful community around supporters of Saas: execs,
                founders, VC's and so on.
              </p>

              <p>
                We've built communities before, around a local Cloud gathering
                of hundreds of enthusiasts, and around a marquee USA event held
                by one of the world's biggest Saas companies for it's customers
                and partners (our tribe for this exceeds a thousand amazing
                humans from APAC).
              </p>

              <p>
                When we say we, we mean that in a catalytic sense. It's friends
                and members of the community and the connections they make and
                the growth they experience through their own contributions and
                efforts that really add impetus to something like this. We'd
                love to hear your ideas on how to make this amazing.
              </p>

              <p>
                <strong>Help us build a community that works for us all</strong>
              </p>

              <p>
                We have some ideas. But what THE TOUR becomes will depend on what the tour wants. So feel free to give us your thoughts and ideas.
              </p>

              <p>
                That's you. A bunch of early stage companies among us? Let's
                organise pitch fests to US-based VC's. Enough Saas companies
                among us targeting a particular vertical? Let's try get a bunch
                of prospects into a room. A skew towards a particular function
                such as product or marketing? Let's get exclusive time with the
                best speakers on topics of interest. You get the picture.
              </p>

              <p>
                The "other" tour we run includes networking lounges, trips to
                Napa and so on. So, hop on board and help us build something
                that works for you too.
              </p>
            </div>
          </div>
        </InnerWrapper>
      </WhyWrapper>
    </>
  );
};

export default WhoWhat;
