import React from "react";

import { COLOR, SCREEN } from "../variables";
import styled from "styled-components";
import Slider from "react-slick";
import InnerWrapper from "../inner-wrapper";
import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  padding: 45px 0 134px 0;
  overflow: hidden;
  @media ${SCREEN.tablet} {
    padding: 80px 0 160px 0;
  }

  h5 {
    color: ${COLOR.green};
    font-weight: 600;
    font-size: 2.3rem;
    margin: 0 0 25px 0;
    @media ${SCREEN.tablet} {
      margin: 0 0 30px 0;
      font-size: 2.5rem;
    }
  }

  .slider-wrapper {
    @media ${SCREEN.tablet} {
      margin: 0 -60px;
    }
    @media ${SCREEN.desktop} {
      margin: 0 -80px;
    }

    .each-slider {
      padding: 0 25px;
      @media ${SCREEN.tablet} {
        padding: 0 60px;
      }
      @media ${SCREEN.desktop} {
        padding: 0 80px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .slick-dots {
      bottom: -60px;

      li {
        margin: 0 10px;
        @media ${SCREEN.tablet} {
          margin: 0 12px;
        }

        button {
          background-color: ${COLOR.grey};
          border-radius: 50%;
          width: 9px;
          height: 9px;
          @media ${SCREEN.tablet} {
            width: 12px;
            height: 12px;
          }

          &::before {
            display: none;
          }
        }
      }

      .slick-active {
        button {
          background-color: ${COLOR.green};
        }
      }
    }
  }
`;

const Gallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Wrapper data-scroll>
      <InnerWrapper>
        <h5>Gallery</h5>
      </InnerWrapper>
      <Slider className="slider-wrapper" {...settings}>
        <div className="each-slider">
          <StaticImage
            src="../../images/saastralia-2022-01.webp"
            alt="Event Snapshot"
            placeholder="blurred"
            quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
            src="../../images/saastralia-2022-02.webp"
            alt="Event Snapshot"
            placeholder="blurred"
            quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
            src="../../images/saastralia-2022-03.webp"
            alt="Event Snapshot"
            placeholder="blurred"
            quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
            src="../../images/saastralia-2022-04.webp"
            alt="Event Snapshot"
            placeholder="blurred"
            quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
            src="../../images/saastralia-2022-05.webp"
            alt="Event Snapshot"
            placeholder="blurred"
            quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
            src="../../images/saastralia-2022-06.webp"
            alt="Event Snapshot"
            placeholder="blurred"
            quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
              src="../../images/saastralia-2022-07.webp"
              alt="Event Snapshot"
              placeholder="blurred"
              quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
              src="../../images/saastralia-2022-09.webp"
              alt="Event Snapshot"
              placeholder="blurred"
              quality={100}
          />
        </div>
        <div className="each-slider">
          <StaticImage
              src="../../images/saastralia-2022-10.webp"
              alt="Event Snapshot"
              placeholder="blurred"
              quality={100}
          />
        </div>
      </Slider>
    </Wrapper>
  );
};

export default Gallery;
