import React from "react";

import { COLOR, SCREEN } from "../variables";
import styled from "styled-components";
import InnerWrapper from "../inner-wrapper";
import { FiMail } from "react-icons/fi";
import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  background-color: ${COLOR.green};
  padding: 45px 0 245px 0;
  position: relative;
  @media ${SCREEN.tablet} {
    padding: 80px 0;
  }

  .flex-row {
    @media ${SCREEN.laptop} {
      display: flex;
    }

    .col {
      .subheading {
        color: ${COLOR.yellow};
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.35;
        @media ${SCREEN.tablet} {
          font-size: 1.75rem;
        }
      }

      &--one {
        display: block;
        margin: 0 0 0 auto;
        @media ${SCREEN.tablet} {
          width: 60%;
          margin: 0 0 0 auto;
        }
        @media ${SCREEN.laptop} {
          width: 40%;
          margin: 0;
        }
      }

      &--two {
        margin: 24px 0 0 auto;
        @media ${SCREEN.tablet} {
          width: 60%;
          max-width: 610px;
          margin: 58px 0 0 auto;
        }
        @media ${SCREEN.laptop} {
          margin: 0;
        }

        h6 {
          color: ${COLOR.yellow};
          font-weight: 600;
          font-size: 2.3rem;
          margin: 0 0 25px 0;
          @media ${SCREEN.tablet} {
            font-size: 2.5rem;
            margin: 0 0 30px 0;
          }

          strong {
            color: ${COLOR.yellow};
            font-weight: 600;
          }
        }

        p {
          color: ${COLOR.white};
          font-size: 1rem;
          @media ${SCREEN.tablet} {
            font-size: 1.1rem;
          }
        }

        .cta {
          margin: 30px 0 0 0;

          .label {
            color: ${COLOR.yellow};
            display: flex;
            align-items: center;
            font-size: 1.175rem;
            margin: 15px 0 0 0;
            @media ${SCREEN.tablet} {
              font-size: 1.3rem;
              margin: 10px 0 0 0;
            }

            &--bold {
              font-weight: 700;
            }

            a {
              color: ${COLOR.white};
              text-decoration: none;
              margin: 0 0 0 10px;
              transition: all 0.2s ease-in-out;
              @media ${SCREEN.cursor} {
                &:hover {
                  color: ${COLOR.yellow};
                }
              }
            }
          }
        }
      }
    }
  }

  .person-img {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 310px;
    @media ${SCREEN.tablet} {
      max-width: 35vw;
    }
    @media ${SCREEN.desktop} {
      max-width: 530px;
    }

    img {
      z-index: 2;
    }

    &::after {
      content: "";
      background-color: ${COLOR.yellow};
      position: absolute;
      width: 100%;
      height: 100%;
      top: -10px;
      right: -10px;
      z-index: 1;
    }
  }
`;

const Contact = () => {
  return (
    <Wrapper id="contact">
      <InnerWrapper>
        <div className="flex-row">
          <div className="col col--one">
            <span className="subheading">Get on Board</span>
          </div>
          <div className="col col--two">
            <h6>So what do you need to do to get on board?</h6>
            <p>
              Simply email us to get access to the discount code and to be added
              to the tour. That's it! We'll then keep you up to date with
              announcements and developments as we grow the delegate list and
              get set for the fun. We look forward to having you on the first
              ever SaaStralia.
            </p>

            <div className="cta">
              <span className="label label--bold">Email us</span>
              <span className="label">
                <FiMail />
                <a href="mailto:rajhev@capital-e.com.au?subject=I want to find out more about SaaStralia">
                  rajhev@capital-e.com.au
                </a>
              </span>
            </div>
          </div>
        </div>
      </InnerWrapper>
      <div className="person-img">
        <StaticImage
          src="../../images/Your-Hosts-SaaStralia.webp"
          alt="Rajhev"
          placeholder="blurred"
          quality={100}
        />
      </div>
    </Wrapper>
  );
};

export default Contact;
