import React, { useEffect } from "react";
import { COLOR, FONT, SCREEN } from "../variables";
import styled from "styled-components";
import InnerWrapper from "../inner-wrapper";

const Wrapper = styled.header`
  background-color: ${COLOR.yellow};
  padding: 50px 0;
  @media ${SCREEN.tablet} {
    padding: 60px 0;
  }

  #countdown {
    display: flex;
    max-width: 720px;
    margin: 0 auto;
    justify-content: space-between;

    p {
      color: ${COLOR.green};
      font-family: ${FONT.heading};
      text-align: center;
      font-size: 0.7rem;
      font-weight: 500;
      @media ${SCREEN.tablet} {
        font-size: 1rem;
      }
    }

    span {
      color: ${COLOR.white};
      display: block;
      font-size: 2.4rem;
      font-weight: 600;
      @media ${SCREEN.tablet} {
        font-size: 3.5rem;
      }
    }
  }

  .footnote {
    color: ${COLOR.white};
    font-family: ${FONT.heading};
    font-weight: 600;
    font-size: 1.25rem;
    margin: 35px 0 0 0;
    line-height: 1.35;
    text-align: center;
    @media ${SCREEN.tablet} {
      font-size: 2.3rem;
    }
  }
`;

const Countdown = () => {
  useEffect(() => {
    // countdown handler
    const end = new Date("09/6/2023 09:00 AM");

    let _second = 1000;
    let _minute = _second * 60;
    let _hour = _minute * 60;
    let _day = _hour * 24;
    let timer;

    function showRemaining() {
      let now = new Date();
      let distance = end - now;
      if (distance < 0) {
        clearInterval(timer);
        document.getElementById(
          "countdown"
        ).innerHTML = `<p><span>Welcome to APAC On Tour! 💃</span></p>`;

        return;
      }
      let days = Math.floor(distance / _day);
      let hours = Math.floor((distance % _day) / _hour);
      let minutes = Math.floor((distance % _hour) / _minute);
      let seconds = Math.floor((distance % _minute) / _second);

      document.getElementById(
        "countdown"
      ).innerHTML = `<p><span>${days}</span>DAYS</p>`;
      document.getElementById(
        "countdown"
      ).innerHTML += `<p><span>${hours}</span>HOURS</p>`;
      document.getElementById(
        "countdown"
      ).innerHTML += `<p><span>${minutes}</span>MINUTES</p>`;
      document.getElementById(
        "countdown"
      ).innerHTML += `<p><span>${seconds}</span>SECONDS</p>`;
    }

    timer = setInterval(showRemaining, 1000);
  }, []);

  return (
    <Wrapper>
      <InnerWrapper>
        <div id="countdown"></div>
        <p className="footnote">Downtimer until SaaStr 2023!</p>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Countdown;
